import React, { ReactElement } from 'react'
import PropTypes from 'prop-types'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import loadable from '@loadable/component'

import { Mdx } from '../../../../types'

const Layout = loadable(
  () => import('@rocketseat/gatsby-theme-docs/src/components/Layout'),
)
const SEO = loadable(
  () => import('@rocketseat/gatsby-theme-docs/src/components/SEO'),
)

export default function Docs({ mdx }: { mdx: Mdx }): ReactElement {
  const { title, description, image, disableTableOfContents } = mdx.frontmatter
    ? mdx.frontmatter
    : {
        title: '',
        description: '',
        image: undefined,
        disableTableOfContents: true,
      }
  const { headings, body } = mdx
  const { slug } = mdx.fields ? mdx.fields : { slug: '' }

  return (
    <>
      <SEO title={title} description={description} slug={slug} image={image} />
      <Layout
        disableTableOfContents={disableTableOfContents}
        title={title}
        headings={headings}
      >
        <MDXRenderer>{body}</MDXRenderer>
      </Layout>
    </>
  )
}

Docs.propTypes = {
  mdx: PropTypes.shape({
    body: PropTypes.string,
    headings: PropTypes.array,
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      disableTableOfContents: PropTypes.bool,
    }),
    fields: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    prev: PropTypes.shape({}),
    next: PropTypes.shape({}),
    githubEditUrl: PropTypes.string,
  }).isRequired,
}
